import { ToastContainer, toast } from "react-toastify";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Button,
  Modal,
  Autocomplete,
  Tooltip,
  createFilterOptions,
  IconButton,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { CloseIcon, PinImage, Uplodeimg } from "../../Assets/Icon";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { Close } from "@mui/icons-material";
import JoditEditor from "jodit-react";

function CreateTicket() {
  const AppCode = global?.$userData?.logindata?.AppCode;
  const AccountsId = global?.$userData?.logindata?.AccountsId;
  const ContactId = global?.$userData?.logindata?.ContactId;
  const config = global.$userData.logindata.crm_app_license_master;

  const isCategory = config.Ticketcategory == "Yes";

  //UseStates
  const [showDepartments, setShowDepartments] = useState(true);
  const [Department, setDepartment] = useState("");
  const [DepartDrop, setDepartDrop] = useState([]);
  const deptDrop = [];
  const [Product, setProduct] = useState("");
  const ProductSelect = [];
  const [productDrop, setProductDrop] = useState([]);

  // category
  const CategorySelect = [];
  const [showCategory, setShowCategory] = useState(false);
  const [Category, setCategory] = useState(null);
  const [categoryDrop, setCategoryDrop] = useState([]);

  const [IssueDrop, setIssueDrop] = useState([]);
  const IssueSelect = [];
  const [showIssues, setShowIssues] = useState(false);
  const [Issues, setIssues] = useState();
  const [priority, setPriority] = useState("medium");
  const [AttachmentLink, setAttachmentLink] = useState("");
  const filter = createFilterOptions();
  const [showProduct, setShowProduct] = useState(false);
  // console.log("Department", Department, "Product", Product, "Issues", Issues);
  const [refresh, setRefresh] = useState(false);

  console.log("global?.$userData?.logindata", global?.$userData);

  /* Jodit Editor*/
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const plainString = content.replace(/<[^>]+>/g, "");

  /*Department, product, issue onFocus */
  const [activeList, setActiveList] = useState("");
  const [productactiveList, setProductActiveList] = useState("");
  const [categoryActiveList, setActiveCategoyList] = useState("");
  const [IssueActiveList, setIssueActiveList] = useState("");

  //Dynamic Product Add PopupOpen
  const [ProductAddOpen, setProductAddOpen] = useState(false);

  const Navigate = useNavigate();

  const handleDepartment = (val) => {
    // console.log(val, "val12345");
    setDepartment(val);
    setShowDepartments(val !== null ? false : true);
    setShowProduct(false);
    if (isCategory) {
      setShowCategory(true);
    } else {
      setShowIssues(true);
    }
  };
  const DepartmentShow = () => {
    setShowDepartments(true);
    setShowProduct(false);
    setShowIssues(false);
  };

  const CategoryShow = () => {
    if (Department) {
      setShowCategory(true);
      setShowDepartments(false);
      setShowIssues(false);
    } else {
      setShowCategory(false);
    }
  };

  const handleCategory = (val) => {
    setCategory(val);
    setShowCategory(val !== null ? false : true);
    setShowIssues(true);
  };

  const handleIssues = (val) => {
    setIssues(val);
    setShowIssues(val !== null ? false : true);
    setShowProduct(true);
  };

  const handleProduct = (val) => {
    setProduct(val);
    setShowProduct(val !== null ? false : true);
    setShowIssues(false);
  };
  const IssueShow = () => {
    if (Department) {
      setShowIssues(true);
      setShowDepartments(false);
      setShowProduct(false);
    } else {
      setShowIssues(false);
    }
  };
  const ProductShow = () => {
    if (showProduct) {
      if (Department && Issues) {
        setShowProduct(true);
        setShowIssues(false);
        setShowDepartments(false);
      } else {
        setShowProduct(false);
      }
    } else {
      setShowProduct(true);
      setShowIssues(false);
      setShowDepartments(false);
    }
  };

  const departmentList = async () => {
    const response = await global.$axios.get(
      `ticket/department/${global.$userData.logindata.AppCode}`
    );
    console.log("`ticket/department", response.data.Message);

    setDepartDrop(response.data.Message);
  };

  const categoryListApi = async (deptid) => {
    if (!deptid || deptid !== deptid) {
      setCategoryDrop([]);
      setCategory(null);
    } else {
      const response = await global.$axios
        .get(`/ticket/getissuecategoryList/${AppCode}/${deptid}`)

        .then(async (response) => {
          // console.log("getissuecategoryList", response.data);

          setCategoryDrop(response.data.Message);
          setCategory(null);
        })
        .catch((e) => {
          console.log("categoryList Issue", e);
        });
    }
  };

  const getIssueListCategory = async (catId) => {
    if (!catId || catId !== catId) {
      setIssueDrop([]);
      setIssues(null);
    } else {
      let payload = {
        AppCode: AppCode,
        IssueCategoryId: catId,
        DepartmentId: Department?.DepartmentId,
      };
      const response = await global.$axios
        .post(`/ticket/getissuecategoryList`, payload)
        .then(async (response) => {
          console.log("getissuecategoryList", response.data);

          setIssueDrop(response.data.Message);
          setIssues(null);
          setIssueActiveList("");
        })
        .catch((e) => {
          console.log("ticket/getissuecategoryList", e);
        });
    }
  };

  const productList = async () => {
    const response = await global.$axios.get(
      `/ticket/products/${global.$userData.logindata.AppCode}/${ContactId}`
    );

    // setShowProduct(true);
    setProductDrop(
      response.data.Message.length > 0 ? response.data.Message : []
    );
    setProduct("");
    setProductActiveList("");
  };
  const issueList = async (deptid) => {
    if (!deptid || deptid != deptid) {
      setIssueDrop([]);
      setIssues(null);
    }
    const response = await global.$axios.get(
      `/ticket/issue/${AppCode}/${deptid}`
    );
    // console.log("issueList", response.data);
    setIssueDrop(response.data.Message);
    setIssues("");
    setIssueActiveList("");
  };

  // Ticket Create
  const createTicket = async () => {
    if (
      AppCode === "" ||
      !(Department != null && Object.values(Department).length !== 0) ||
      !(Product != null && Object.values(Product).length !== 0) ||
      !(Issues != null && Object.values(Issues).length !== 0) ||
      priority === "" ||
      content === "" ||
      content === "<p><br></p>"
    ) {
      toast.error("You Missed Required Fields");
      return;
    } else {
      let payload = {
        AppCode: AppCode,
        AccountId: AccountsId,
        ContactId: ContactId,
        DepartmentId: Department?.DepartmentId,
        IssueId: Issues?.IssueId,
        PriorityLevel: priority,
        AttachmentFile: AttachmentLink,
        TicketDescription: content,
        ProductId: Product?.ProductId,
        IssueCategoryId: Category?.IssueCategoryId ?? "",
      };
      console.log("payload", payload);
      const response = await global.$axios.post(
        `/customerticket/create`,
        payload
      );
      console.log("customerticket", response.data);

      if (response.data.Status == 200) {
        toast.success("Ticket Created Sucessfully");
        setTimeout(() => {
          // window.location.reload(false);
          Navigate("/app/Ticketstatus");
        }, 1500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  useEffect(() => {
    departmentList();
    productList();
  }, []);
  return (
    <>
      <Grid container>
        <Grid container style={{ alignItems: "center", display: "flex" }}>
          <span
            style={{
              color: "#0071BC",
              fontSize: "22px",
              fontWeight: 600,
              padding: "0px 0px 0px 5px",
            }}
          >
            {" "}
            New Ticket
          </span>
        </Grid>

        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0px 0px 0px",
          }}
        ></Grid>
        <div
          style={{
            cursor: "pointer",
            margin: "5px 0px 0px 0px",
          }}
        >
          <span
            style={style5.title}
            onClick={() => {
              DepartmentShow();
            }}
          >
            Department <span style={{ color: "darkred" }}>*</span>
          </span>
          {Department ? (
            <span
              style={style5.title2}
              onClick={() => setShowDepartments(false)}
            >
              / {Department.DepartmentName}
            </span>
          ) : null}
        </div>
        {showDepartments ? (
          <Grid container xs={12} style={{}}>
            <Grid
              container
              item
              lg={8.5}
              xs={12}
              style={{ gap: "10px", display: "flex", margin: "5px 0px" }}
            >
              {DepartDrop.map((Department, index) => {
                if (index <= 5) {
                  return (
                    <Grid
                      item
                      md={1.7}
                      sm={8}
                      xs={12}
                      style={
                        activeList === Department.DepartmentName
                          ? style5.cardsActive
                          : style5.cards
                      }
                      onClick={() => {
                        if (!Department.DepartmentId) {
                          // productList(null);
                          issueList(null);
                          categoryListApi(null);
                        } else {
                          isCategory
                            ? categoryListApi(Department.DepartmentId)
                            : issueList(Department.DepartmentId);
                        }
                        handleDepartment(Department);
                        setActiveList(Department.DepartmentName);
                      }}
                      key={index}
                    >
                      {Department.DepartmentName.substr(0, 20)}
                    </Grid>
                  );
                } else {
                  deptDrop?.push(Department);
                }
              })}
            </Grid>
            <Grid
              item
              md={3.5}
              xs={5}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0px 0px 0px",
              }}
            >
              {DepartDrop.length > 6 && (
                <Autocomplete
                  id="departement drop"
                  fullWidth
                  options={deptDrop}
                  value={Department}
                  getOptionLabel={(option) => option.DepartmentName || ""}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="select Department" />
                  )}
                  onChange={(event, newvalue) => {
                    handleDepartment(newvalue);
                    setDepartment(newvalue);
                    isCategory
                      ? categoryListApi(newvalue.DepartmentId)
                      : issueList(newvalue.DepartmentId);
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : null}

        {/* category  */}
        {isCategory && (
          <Grid container style={{ cursor: "pointer", marginTop: "5px" }}>
            <div>
              <span
                style={style5.title}
                onClick={() => {
                  CategoryShow();
                }}
              >
                Category <span style={{ color: "darkred" }}>*</span>
              </span>

              {Category ? (
                <span
                  style={style5.title2}
                  onClick={() => setShowCategory(false)}
                >
                  / {Category.IssueCategroyName}
                </span>
              ) : null}
            </div>
          </Grid>
        )}

        {showCategory ? (
          <Grid container>
            <Grid
              container
              item
              lg={8.5}
              xs={12}
              style={{ gap: "10px", display: "flex", margin: "5px 0px" }}
            >
              {categoryDrop?.length > 0 ? (
                categoryDrop.map((categ, index) => {
                  console.log("categ", categ);

                  if (index < 6) {
                    return (
                      <Grid
                        item
                        md={1.7}
                        sm={8}
                        xs={12}
                        style={
                          categoryActiveList === categ.IssueCategroyName
                            ? style5.cardsActive
                            : style5.cards
                        }
                        onClick={() => {
                          handleCategory(categ);
                          getIssueListCategory(categ.IssueCategoryId);
                          setActiveCategoyList(categ.IssueCategroyName);
                        }}
                        key={index}
                      >
                        {categ.IssueCategroyName}
                      </Grid>
                    );
                  } else {
                    CategorySelect?.push(categ);
                  }
                })
              ) : (
                <span style={{ color: "darkred" }}>
                  No Category Found In This Department.
                </span>
              )}
            </Grid>
            <Grid
              item
              lg={3.5}
              xs={5}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {CategorySelect.length > 0 && (
                <Autocomplete
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  value={Issues}
                  options={CategorySelect}
                  getOptionLabel={(option) => option.IssueCategroyName}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.IssueCategoryId}>
                      {option.IssueCategroyName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Category"
                      variant="outlined"
                      required
                    />
                  )}
                  onChange={(event, newValue) => {
                    setCategory(newValue);
                    handleIssues(newValue);
                    setActiveCategoyList(newValue);
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : null}

        <Grid container style={{ cursor: "pointer", marginTop: "5px" }}>
          <span
            style={style5.title}
            onClick={() => {
              IssueShow();
            }}
          >
            Issue <span style={{ color: "darkred" }}>*</span>
          </span>
          {Issues ? (
            <span style={style5.title2} onClick={() => setShowIssues(false)}>
              / {Issues.IssueName}
            </span>
          ) : null}
        </Grid>
        {showIssues ? (
          <Grid container>
            <Grid
              container
              item
              lg={8.5}
              xs={12}
              style={{ gap: "10px", display: "flex", margin: "5px 0px" }}
            >
              {IssueDrop?.length > 0 ? (
                IssueDrop.map((Issues, index) => {
                  if (index < 6) {
                    return (
                      <Grid
                        item
                        md={1.7}
                        sm={8}
                        xs={12}
                        style={
                          IssueActiveList === Issues.IssueName
                            ? style5.cardsActive
                            : style5.cards
                        }
                        onClick={() => {
                          handleIssues(Issues);
                          setIssueActiveList(Issues.IssueName);
                        }}
                        key={index}
                      >
                        {Issues.IssueName}
                      </Grid>
                    );
                  } else {
                    IssueSelect?.push(Issues);
                  }
                })
              ) : (
                <span style={{ color: "darkred" }}>
                  No Issues Found In This Department.
                </span>
              )}
            </Grid>
            <Grid
              item
              lg={3.5}
              xs={5}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {IssueDrop.length > 6 && (
                // <TextField
                //     id="outlined"
                //     select
                //     size="small"
                //     label="IssuesDrop"
                //     sx={{ borderRadius: "10px", width: "100%" }}
                // >
                //     {IssueSelect.map((Issues, index) => (
                //         <MenuItem
                //             key={index}
                //             values={[Issues ? Issues : []]}
                //             onClick={() => {
                //                 handleIssues(Issues);
                //             }}
                //         >
                //             {Issues.IssueName}
                //         </MenuItem>
                //     ))}
                // </TextField>
                <Autocomplete
                  id="Product drop"
                  fullWidth
                  options={IssueSelect}
                  value={Issues}
                  getOptionLabel={(option) => option.IssueName || ""}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Issues" />
                  )}
                  onChange={(event, newvalue) => {
                    handleIssues(newvalue);
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : null}
        <Grid
          container
          xs={12}
          style={{
            marginTop: "8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item style={{ cursor: "pointer" }}>
            <span
              style={style5.title}
              onClick={() => {
                ProductShow();
              }}
            >
              Product <span style={{ color: "darkred" }}>*</span>
            </span>
            {Product ? (
              <span style={style5.title2} onClick={() => setShowProduct(false)}>
                / {Product.ProductName}
              </span>
            ) : null}
          </Grid>

          {showProduct ? (
            <Grid container>
              <Grid
                container
                item
                lg={8.5}
                xs={12}
                style={{ gap: "10px", display: "flex", margin: "5px 0px" }}
              >
                {productDrop?.length > 0 ? (
                  productDrop?.map((Product, index) => {
                    if (index <= 5) {
                      return (
                        <Grid
                          item
                          md={1.7}
                          sm={8}
                          xs={12}
                          style={
                            productactiveList === Product?.ProductName
                              ? style5.cardsActive
                              : style5.cards
                          }
                          onClick={() => {
                            handleProduct(Product);
                            setProductActiveList(Product?.ProductName);
                          }}
                          key={index}
                        >
                          {Product?.ProductName.substr(0, 20)}
                        </Grid>
                      );
                    } else {
                      ProductSelect?.push(Product);
                    }
                  })
                ) : (
                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "darkred" }}>
                      Sorry! No Products Available For This Company.
                    </span>
                  </Grid>
                )}
              </Grid>

              <Grid
                item
                lg={3.5}
                xs={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {productDrop?.length > 6 ||
                  productDrop != null ||
                  (productDrop != " " && (
                    // <TextField
                    //     id="outlined"
                    //     select
                    //     size="small"
                    //     label="ProductDrop"
                    //     sx={{ borderRadius: "10px", width: "100%" }}
                    // >
                    //     {ProductSelect?.map((Product, index) => {
                    //         <MenuItem
                    //             key={index}
                    //             value={[Product ? Product : []]}
                    //             onClick={() => {
                    //                 handleProduct(Product);
                    //             }}
                    //         >
                    //             {Product.ProductName}
                    //         </MenuItem>;
                    //     })}
                    // </TextField>
                    <Autocomplete
                      id="Product drop"
                      fullWidth
                      options={ProductSelect}
                      value={Product}
                      getOptionLabel={(option) => option.ProductName || ""}
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label="Select Product" />
                      )}
                      onChange={(event, newvalue) => {
                        handleProduct(newvalue);
                      }}
                    />
                  ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        <Grid container xs={12}>
          <p style={style5.title}> Priority</p>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="medium"
                value={priority}
                size="small"
                onClick={(event) => {
                  setPriority(event.target.value);
                }}
              >
                <FormControlLabel
                  value="high"
                  control={<Radio />}
                  label="High"
                  sx={{ ...formControlLabelStyle }}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="medium"
                  control={<Radio />}
                  label="Medium"
                  sx={{ ...formControlLabelStyle }}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="low"
                  control={<Radio />}
                  label="Low"
                  sx={{ ...formControlLabelStyle }}
                  labelPlacement="top"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          md={7.7}
          xs={12}
          container
          item
          style={{
            margin: "20px 0px 10px 0px",
          }}
        >
          <Grid item xs={12}>
            <p
              style={{
                padding: "10px 15px",
                background: "rgba(0, 113, 188, 0.1)",
                margin: "0px",
              }}
            >
              Description
              <span style={{ color: "darkred" }}> *</span>
            </p>
            {/* <JoditEditor
                            ref={editor}
                            value={content}
                            onBlur={(e) => setContent(e)}
                        /> */}
            <JoditEditor
              ref={editor}
              value={content}
              onBlur={(e) => setContent(e)}
            />
          </Grid>
          <Grid item style={{ margin: "10px 0px 0px 0px" }}>
            <p
              style={{
                fontSize: "14px",
                margin: "0px 0px 5px 0px",
              }}
            >
              Add Media Files Here If You Don't Have URL...
            </p>{" "}
            <Attachment
              setAttachmentLink={setAttachmentLink}
              AttachmentLink={AttachmentLink}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Grid>
        </Grid>
        <Grid
          container
          md={11}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px",
            margin: "10px 0px",
            borderTop: "1px solid rgb(0,0,0,0.1)",
          }}
        >
          <Button
            size="small"
            variant="contained"
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "10px 20px",
              margin: "5px 0px 0px 0px",
              // background: "linear-gradient(to bottom left, #051937, #004d7a, #008793, #00bf72, #a8eb12)"
            }}
            onClick={() => {
              createTicket();
            }}
          >
            Save
          </Button>
          {/* <Button
                        size="small"
                        variant="contained"
                        // color=""

                        sx={{


                            maxHeight: "22px",
                            minHeight: "22px",
                            textTransform: "none",
                            padding: "10px 20px",
                            margin: "5px 0px 0px 0px",
                            // background: "linear-gradient(to bottom left, #051937, #004d7a, #008793, #00bf72, #a8eb12)"
                        }}
                        onClick={() => {
                            // ClosecreateTicket();
                        }}
                    >
                        Save & New Ticket

                    </Button> */}
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#232323"}
        />
      </Grid>
    </>
  );
}

export default CreateTicket;

const style5 = {
  cards: {
    background: "#F4F4F4",
    boxShadow:
      "1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(176, 176, 176, 0.5), inset -3px 3px 6px rgba(176, 176, 176, 0.2), inset 3px -3px 6px rgba(176, 176, 176, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.9), inset 3px 3px 8px rgba(176, 176, 176, 0.9)",
    borderRadius: "10px",
    fontSize: "14px",
    border: "hidden",
    textTransform: "none",
    fontWeight: 400,

    cursor: "pointer",
    padding: "20px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  cardsActive: {
    boxShadow:
      "1px 1px 2px rgba(255, 255, 255, 04), -1px -1px 2px rgba(176, 176, 176, 0.5), inset -3px 3px 6px rgba(176, 176, 176, 0.2), inset 3px -3px 6px rgba(176, 176, 176, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.9), inset 3px 3px 8px rgba(176, 176, 176, 0.9)",
    borderRadius: "10px",
    borderBottom: "4px solid #0071BC",
    background: "rgba(10, 124, 198, 0.1)",
    fontSize: "14px",
    textTransform: "none",
    fontWeight: 500,
    cursor: "pointer",
    padding: "20px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    fontSize: "14px",
    fontWeight: 500,
    margin: "10px 0px 10px 0px",
  },
  title2: {
    color: "#0071BC",
    fontSize: "22px",
    fontWeight: 600,
    paddingLeft: "5px",
  },
  Commentimg: {
    height: "29px",
    width: "29px",
  },
};
const formControlLabelStyle = {
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
  },
};

const stylebox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  outline: "transparent",
  maxHeight: "60vh",
  overflowY: "auto",
  overflowX: "hidden",
};
function Attachment({
  setAttachmentLink,
  AttachmentLink,
  setRefresh,
  refresh,
}) {
  const [pin, setpin] = useState(false);
  const handleOpenpin = () => setpin(true);
  const handleClosepin = () => setpin(false);

  const [vision, setvision] = useState("");
  var d = 0;
  function getTimestampInSeconds() {
    d++;
    setvision(d);
  }

  const [file, setFile] = useState([]);

  // const imageChange = (e) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     setFile([e.target.files]);
  //   }
  // };

  // const handleFileChange = (e) => {
  //   if (e.target.files) {
  //     setFile(e.target.files);
  //   }
  // };
  const handleFileChange = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleUploadFiles = (files) => {
    const Files = [...file];
    const MAX_COUNT = 5;
    let limitExceeded = false;
    files.some((file) => {
      if (Files.findIndex((f) => f.name === file.name) === -1) {
        Files.push(file);
        if (Files.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setFile(Files);
  };

  const handleUploadClick = async () => {
    {
      file.forEach(async (files, index) => {
        var formData = new FormData();
        formData.append("file", files);

        const response = await global.$axios
          .post("ticket/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          })
          .then(async (response) => {
            if (response.data.Status === 200) {
              setAttachmentLink((AttachmentLink) => [
                ...AttachmentLink,
                response.data.Message,
              ]);
              handleClosepin();
              getTimestampInSeconds();
            } else {
              toast.error("Something Went Wrong");
            }
          })

          .catch((e) => {
            console.error("Upload API Issue", e);
          });
      });
    }
    if (AttachmentLink.length == vision) {
      toast.success("File uploded Sucessfully");
    }
  };

  const removeFileAtIndex = (index) => {
    file.splice(index, 1);
    setFile(file);
    setRefresh(!refresh);
  };
  const removeAttachementIndex = (index) => {
    AttachmentLink.splice(index, 1);
    setAttachmentLink(AttachmentLink);
    setRefresh(!refresh);
  };
  return (
    <>
      {AttachmentLink.length >= 1 ? (
        <span>
          {file.map((item, index) => {
            return (
              <>
                <span>{item.name}</span>
                <IconButton
                  onClick={() => {
                    removeAttachementIndex(index);
                    removeFileAtIndex(index);
                  }}
                >
                  {" "}
                  <Close />
                </IconButton>
              </>
            );
          })}
        </span>
      ) : (
        <>
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={handleOpenpin}
          >
            <PinImage style={{ width: "18px", height: "18px" }} />
            <span style={{ fontSize: "12px" }}>Insert a File...</span>
          </div>
          <Modal
            open={pin}
            onClose={handleClosepin}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={stylebox}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 0px",
                  borderBottom: "1px solid rgba(0,0,0,0.04)",
                }}
              >
                <span style={{ color: "#0071BC", fontWeight: 500 }}>
                  Attachment File
                </span>
                <IconButton
                  onClick={() => {
                    handleClosepin();
                    setFile([]);
                  }}
                  sx={{ padding: "0px" }}
                >
                  <CloseIcon style={{ width: "25px", height: "25px" }} />
                </IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Uplodeimg />
              </div>
              <Grid
                container
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{ width: "70%" }}
                    type="file"
                    accept="text"
                    id="file"
                    multiple
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                  />
                </div>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {file && (
                    <div
                      style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span style={{ fontSize: "10px" }}>
                        Tab on image to preview
                      </span>
                      {file.map((file, index) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Link
                                target="blank"
                                to={URL.createObjectURL(file)}
                              >
                                <img
                                  src={URL.createObjectURL(file)}
                                  style={{ maxWidth: 200, maxHeight: 200 }}
                                  alt="Thumb"
                                />
                              </Link>
                              <button
                                // onClick={() => setFile("")}
                                onClick={() => removeFileAtIndex(index)}
                                style={{
                                  cursor: "pointer",
                                  padding: 1,
                                  background: "red",
                                  color: "white",
                                  border: "none",
                                }}
                              >
                                {" "}
                                Remove This Image
                              </button>
                            </Grid>
                          </>
                        );
                      })}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                style={{
                  margin: "5px",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "13px",
                }}
              >
                <span>
                  Maximum size: <span style={{ color: "darkred" }}>5MB</span>
                </span>

                <Grid
                  container
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "5px 0px",
                  }}
                ></Grid>

                <Button
                  style={{
                    maxHeight: "22px",
                    minHeight: "22px",
                    textTransform: "none",
                  }}
                  disabled={file.length >= 1 ? false : true}
                  variant="contained"
                  onClick={() => {
                    handleUploadClick();
                  }}
                >
                  Upload
                </Button>
              </Grid>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
