import { Box, Button, Grid, Tab, Tabs, TextField } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Batman } from "../../Assets/Icon";
import { toast, ToastContainer } from "react-toastify";
import { connect, useDispatch } from "react-redux";

const Login = ({ setforget }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const decoded = atob(urlParams.get("mn") !== null ? urlParams.get("mn") : "");
  const decoded2 = atob(decoded !== null ? decoded : "");

  const [otp, setotp] = useState("mobilenumber");

  const [number, setNumber] = useState(decoded2 !== null ? decoded2 : "");
  // console.log(number);
  const [pinnumber, setPinNumber] = useState("");

  const [account, setAccount] = useState([]);

  const [account1, setaccount1] = useState([]);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const width = window.innerWidth;
  const handleChange = (newValue) => {
    setPinNumber(newValue);
  };

  const handleComplete = (finalValue) => {
    otpValidate(finalValue);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      phoneNumber();
    }
  };

  const phoneNumber = async () => {
    if (!number) {
      toast.error("Please Enter correct MobileNumber");
      return;
    }
    const response = await global.$axios.post(`auth/customerlogin/${number}`);
    console.log("response", response.data);

    if (response.data.status == "200") {
      setTimeout(() => {
        setotp("verfication");
      }, [1500]);
      toast.success("OTP Sent Successfully");
    } else {
      toast.error("Please Recheck You Mobile Number");
    }
  };

  const otpValidate = async (finalValue) => {
    let payload = {
      Otp: finalValue,
      MobileNumber: number,
    };
    console.log("payload", payload);

    const response = await global.$axios.post(
      "auth/customervalidateotp",
      payload
    );
    console.log("response", response.data);
    if (response.data.status === 200) {
      if (response.data.message.length == 1) {
        dispatch({
          type: "SET_USER_IN",
          logindata: response.data.message[0],
          payload: true,
        });
        setTimeout(() => {
          Navigate("/app/CreateTicket");
        }, 1500);
        toast.success("Login Success");
      } else if (response.data.message.length >= 1) {
        setotp("SelectAccount");
        setAccount(response.data.message);
        toast.success("Please select Account");
      } else {
        toast.error("Please Contact Your Admin");
      }
    } else {
      toast.error("Please Enter Correct OTP");
    }
  };

  return (
    <>
      {otp === "mobilenumber" && (
        <>
          <div>
            <div style={{ marginTop: "10px" }}>
              <TextField
                id="username"
                fullWidth
                autoFocus
                inputProps={{ maxLength: "10" }}
                placeholder="Enter The Mobile Number"
                variant="outlined"
                size="small"
                onKeyDown={handleKeyDown}
                onChange={(e) => setNumber(e.target.value)}
                value={number}
                style={{
                  background: "rgba(255, 255, 255, 1)",
                  marginTop: "10px",
                  borderRadius: "5px",
                }}
              />
            </div>
            <Grid
              container
              xs={12}
              style={{
                fontSize: "14px",
                color: "white",
                marginTop: "10px",
                fontWeight: "400",
                display: "flex",
                justifyContent: "center",
              }}
            >
              OTP will Be Send To This Mobile Number
            </Grid>

            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  phoneNumber();
                }}
                style={style.button}
              >
                GET OTP
              </Button>
            </Grid>
          </div>
        </>
      )}
      {otp === "verfication" && (
        <>
          <div>
            <Grid
              container
              md={12}
              xs={12}
              style={{
                fontSize: "14px",
                color: "white",
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Enter the OTP sent to mobile number
            </Grid>
            <Grid
              container
              xs={12}
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <MuiOtpInput
                length={4}
                onComplete={handleComplete}
                value={pinnumber}
                autoFocus
                gap={width > 1440 ? 8 : 5}
                onChange={handleChange}
                TextFieldsProps={{
                  sx: {
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    borderColor: "@232323",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Resend OTP
              </span>
            </Grid>
          </div>
        </>
      )}
      {otp === "SelectAccount" && (
        <>
          <div>
            <p
              style={{
                fontSize: "14px",
                color: "white",
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Select Your Account
            </p>
            <Box
              sx={{ maxWidth: { xs: 420, sm: 580 }, bgcolor: "transparent" }}
            >
              <Tabs
                value={account}
                variant="scrollable"
                textColor="secondary"
                TabIndicatorProps={{ sx: { backgroundColor: "green" } }}
                onChange={(e, newValue) => {
                  setaccount1(newValue);
                  toast.success(`Welcome ${newValue?.FirstName} `);
                  setTimeout(() => {
                    Navigate("/app/CreateTicket");
                  }, 1000);
                  dispatch({
                    type: "SET_USER_IN",
                    logindata: newValue,
                    payload: true,
                  });
                }}
                scrollButtons="auto"
                sx={{
                  ".css-1aquho2-MuiTabs-indicator ": {
                    backgroundColor: "white",
                  },
                  ".css-15dm1r7-MuiButtonBase-root-MuiTab-root ": {
                    color: "white",
                    fontSize: "14px",
                  },
                }}
              >
                {account.map((account, index) => {
                  return (
                    <Tab
                      icon={<Batman />}
                      key={index}
                      label={account.account.CompanyName.slice(0, 11)}
                      value={account}
                    />
                  );
                })}
              </Tabs>
            </Box>
          </div>
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userState: state.user,
  };
};
const mapDispatchToprops = (dispatch) => {
  return {
    login: (getUserDate) => {
      dispatch({ type: "SET_USER_IN", getUserDate });
    },
    logout: () => {
      dispatch({ type: "SET_USER_OUT" });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToprops)(Login);

// export default Login;
const style = {
  button: {
    minHeight: "35px",
    maxHeight: "35px",
    color: "rgb(45,113,188)",
    backgroundColor: "white",
    borderRadius: "10px",
    fontWeight: "700",
    textTransform: "none",
  },
};
