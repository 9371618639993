import axios from "axios";
// axios start
// axios.defaults.baseURL = "http://172.16.5.213:4002/";
// axios.defaults.baseURL = "http://172.16.5.67:4002";
// axios.defaults.baseURL = "http://172.16.5.67:4020";
// axios.defaults.baseURL = "https://bkticketapi.treeone.one/";

//staging
//axios.defaults.baseURL = "https://bkapistaging.treeone.one/";
//Live
axios.defaults.baseURL = "https://bkticketapi.treeone.one/";

global.$axios = axios;
global.$test = "axios";
// axios end
